<template>
  <base-modal v-model:open="isModalActive" hide-footer>
    <template v-slot:button>
      <slot name="button">
        <button class="rounded-full h-8 w-8 bg-white hover:bg-gray-200 focus:outline-none transition duration-300 ease-in-out shadow">
          <i class="fas fa-camera text-xl"></i>
        </button>
      </slot>
    </template>

    <div class="card-body py-4 space-y-6">
      <div v-if="imageList && imageList.length">
        <div class="mb-4 text-left">
          {{ $t('page.settings.profile_identification.sentence.profile_image') }}
        </div>

        <div class="flex items-center justify-center space-x-4">
          <div
            v-for="(social, index) in imageList"
            :key="index"
            class="flex flex-col justify-center items-center cursor-pointer"
            @click="changeImage(index)"
          >
            <img
              class="w-12 h-12 m-2 rounded-full transition duration-200 ease-in-out"
              :class="{ 'ring-4 ring-blue-600 transform scale-105': index === selectedImageIndex }"
              :src="social.image"
              @error="imageFallback"
            />
            <div class="text-xs font-semibold">
              {{ social.source }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-footer sm:flex sm:flex-row-reverse space-y-3 sm:space-y-0 sm:space-x-reverse sm:space-x-3"
    >
      <button
        @click="save"
        type="button"
        class="btn btn-primary w-full sm:w-auto sm:text-sm"
      >
        {{ $t('action.save') }}
      </button>
      <button
        @click="closeModal"
        type="button"
        class="btn w-full sm:w-auto sm:text-sm"
      >
        {{ $t('action.cancel') }}
      </button>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import BaseModal from '../base/BaseModal.vue'
import { useToggle } from '@/composables/useToggle'
import { firestore } from '@/plugins/firebase'
import { useProfile } from '@/composables/profile/useProfile'

export default defineComponent({
  components: { BaseModal },

  emits: [
    'update:profileImage'
  ],

  setup (props, {emit}) {
    const imageList = ref<any[]>()
    const selectedImage = ref<string | undefined>()

      const {
      isActive: isModalActive,
      setInactive: closeModal
    } = useToggle(false)

    const { profile } = useProfile()

    onMounted(async () => {
      if (unref(profile).profilePick) {
        selectedImage.value = unref(profile).profilePick
      }
      fetchImageList()
    })

    const fetchImageList = async () => {
      firestore.collection('socialAccounts')
        .doc(unref(profile).id)
        .onSnapshot((accountsDoc) => {
          imageList.value = []

          if (accountsDoc.exists) {
            const accountsData = accountsDoc.data()

            if (accountsData) {
              const { facebook, instagram, linkedin, twitter } = accountsData

              if (instagram && instagram.selectedPage && instagram.pictureStorage) {
                imageList.value.push({
                  image: instagram.pictureStorage,
                  source: 'Instagram',
                  selected: unref(profile).profilePick === instagram.pictureStorage
                })
              }
              
              if (facebook && facebook.selectedPage && facebook.selectedPage.pictureStorage) {
                imageList.value.push({
                  image: facebook.selectedPage.pictureStorage,
                  source: 'Facebook',
                  selected: unref(profile).profilePick === facebook.selectedPage.pictureStorage
                })
              }

              if (linkedin && linkedin.selectedPage && linkedin.selectedPage.logo) {
                imageList.value.push({
                  image: linkedin.selectedPage.logo,
                  source: 'Linkedin',
                  selected: unref(profile).profilePick === linkedin.selectedPage.logo
                })
              }

              if (twitter && twitter.profile_image_url) {
                imageList.value.push({
                  image: twitter.profile_image_url,
                  source: 'Twitter',
                  selected: unref(profile).profilePick === twitter.profile_image_url
                })
              }
            }
          }
        })
    }

    const imageFallback = async (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      element.src = require('@/assets/profile/manyrobot-profile.svg')
    }

    const changeImage = (index: number) => {
      if (imageList.value) {
        selectedImage.value = imageList.value[index].image
      }
    }

    const save = () => {
      emit('update:profileImage', selectedImage.value)
      closeModal()
    }

    const selectedImageIndex = computed(() => {
      if (imageList.value && imageList.value.length) {
        return imageList.value.findIndex(acc => acc.image === selectedImage.value)
      }
      return null
    })

    return {
      isModalActive,
      closeModal,
      imageList,
      imageFallback,
      changeImage,
      selectedImageIndex,
      save,
    }
  }
})
</script>

<style scoped>

</style>