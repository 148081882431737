
// eslint-disable-next-line no-unused-vars
import { useProfile } from '@/composables/profile/useProfile'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { computed, defineComponent, ref, watch } from 'vue'
import ProfileImageSelectorModal from './ProfileImageSelectorModal.vue'

export default defineComponent({
  components: { ProfileImageSelectorModal },

  props: {
    form: {
      type: Object,
      required: true
    },
    currentErrors: {
      type: Object,
      default: () => {}
    }
  },

  emits: [
    'update:form'
  ],

  setup (props, { emit }) {
    const { profile: currentProfile } = useProfile()
    const {plan} = currentProfile.value

    const profile = ref<any>({
      name: props.form.name,
      website: props.form.website,
      profilePick: props.form.profilePick
    })

    const {
      socialIcons
    } = useSocialAccounts()

    const socialButtons = computed(() => {
      return Object.entries(socialIcons.value)
      .reduce((acc, [key, value]) => {
        // Verifica se a classe já existe no acumulador
        if (!acc.some((item: any) => item.class === value.class)) {
          // Se não existir, adiciona o novo item
          acc.push({
            source: key,
            ...value
          });
        }
        return acc;
      }, [] as any)
    })

    watch(profile, (newValue) => {
      emit('update:form', {
        ...newValue
      })
    }, { deep: true })

    const updateProfileImage = (profileImageUrl: string) => {
      profile.value.profilePick = profileImageUrl
    }

    const subscription = computed(() => {
      return {
        active: plan.active,
        plan: plan.name,
        billingDay: plan.billingDay
      }
    })

    return {
      profile,
      socialButtons,
      subscription,
      updateProfileImage,
    }
  }
})
